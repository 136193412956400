if (module.hot) {
  module.hot.accept();
}

const LOGO_CLASS_NAME = 'gpush-inline';
const LOGO_TAG_NAME = 'em';
const LOGO_FILL_ATTR = 'data-fill-color';
const LOGO_FILL_DEFAULT = '#FFFCFF';
const LOGO_FONT_SIZE_TO_SVG_PROPS_MAPPING = {
  16: {
    top: '6px', width: 64, height: 22, scale: 1,
  },
  20: {
    top: '7px', width: 70, height: 24, scale: 1.09,
  },
  32: {
    top: '11px', width: 111, height: 38, scale: 1.73,
  },
  48: {
    top: '16px', width: 170, height: 58, scale: 2.64,
  },
};

const getLogoSVG = (fontSize, fillColor) => {
  const params = LOGO_FONT_SIZE_TO_SVG_PROPS_MAPPING[fontSize];
  if (!params) {
    return false;
  }
  const {
    top, width, height, scale,
  } = LOGO_FONT_SIZE_TO_SVG_PROPS_MAPPING[fontSize];
  return `<svg style="top: ${top}; position: relative;" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path transform="scale(${scale})" d="M29.1684 5.49734C29.1684 6.29282 28.9716 7.03858 28.5782 7.73462C28.1993 8.43067 27.5945 8.99177 26.7638 9.41792C25.9478 9.84407 24.9131 10.0571 23.6598 10.0571L21.1023 10.0571L21.1023 15.7676L18.042 15.7676L18.042 0.894915L23.6598 0.894915C24.8402 0.894915 25.8458 1.09378 26.6764 1.49152C27.5071 1.88926 28.1264 2.43616 28.5345 3.1322C28.9571 3.82825 29.1684 4.61663 29.1684 5.49734ZM23.5287 7.64939C24.3739 7.64939 25.0005 7.46473 25.4086 7.0954C25.8166 6.71186 26.0206 6.17918 26.0206 5.49734C26.0206 4.04843 25.19 3.32397 23.5287 3.32397L21.1023 3.32397L21.1023 7.64939L23.5287 7.64939Z" fill="${fillColor}"/>
    <path transform="scale(${scale})" d="M41.0248 3.9632L41.0248 15.7676L37.9427 15.7676V14.276C37.5492 14.7874 37.0319 15.1923 36.3906 15.4906C35.764 15.7747 35.0791 15.9167 34.3359 15.9167C33.3886 15.9167 32.5507 15.7249 31.8221 15.3414C31.0934 14.9437 30.5178 14.3684 30.0952 13.6155C29.6871 12.8484 29.4831 11.9393 29.4831 10.8881L29.4831 3.9632L32.5434 3.9632L32.5434 10.462C32.5434 11.3995 32.7839 12.124 33.2648 12.6354C33.7457 13.1325 34.4014 13.3811 35.2321 13.3811C36.0773 13.3811 36.7404 13.1325 37.2213 12.6354C37.7022 12.124 37.9427 11.3995 37.9427 10.462L37.9427 3.9632L41.0248 3.9632Z" fill="${fillColor}"/>
    <path transform="scale(${scale})" d="M46.9297 15.9593C45.9388 15.9593 45.0498 15.7889 44.2629 15.4479C43.476 15.0928 42.8493 14.617 42.383 14.0203C41.9312 13.4237 41.6835 12.7632 41.6398 12.0387L44.7219 12.0387C44.7802 12.4933 45.0061 12.8697 45.3996 13.168C45.8076 13.4663 46.3104 13.6155 46.9079 13.6155C47.4908 13.6155 47.9425 13.5019 48.2631 13.2746C48.5983 13.0473 48.7659 12.7561 48.7659 12.401C48.7659 12.0174 48.5619 11.7333 48.1538 11.5487C47.7604 11.3498 47.1265 11.1367 46.2521 10.9094C45.3486 10.6964 44.6054 10.4762 44.0224 10.2489C43.4541 10.0216 42.9586 9.67361 42.536 9.20484C42.128 8.73608 41.9239 8.10396 41.9239 7.30847C41.9239 6.65504 42.1134 6.05843 42.4923 5.51864C42.8858 4.97885 43.4395 4.5527 44.1536 4.24019C44.8822 3.92768 45.7347 3.77143 46.7111 3.77143C48.1538 3.77143 49.3051 4.12655 50.1649 4.8368C51.0247 5.53285 51.4983 6.47748 51.5858 7.6707L48.6566 7.6707C48.6129 7.20194 48.4089 6.83261 48.0445 6.56271C47.6948 6.27861 47.2212 6.13656 46.6237 6.13656C46.0699 6.13656 45.64 6.236 45.334 6.43487C45.0425 6.63374 44.8968 6.91073 44.8968 7.26586C44.8968 7.6636 45.1008 7.96901 45.5089 8.18208C45.9169 8.38095 46.5508 8.58693 47.4106 8.8C48.285 9.01308 49.0064 9.23325 49.5747 9.46053C50.143 9.68781 50.6312 10.0429 51.0393 10.5259C51.4619 10.9947 51.6805 11.6197 51.695 12.401C51.695 13.0828 51.4983 13.6936 51.1049 14.2334C50.726 14.7732 50.1722 15.1994 49.4435 15.5119C48.7295 15.8102 47.8915 15.9593 46.9297 15.9593Z" fill="${fillColor}"/>
    <path transform="scale(${scale})" d="M59.2785 3.79274C60.1966 3.79274 61.0127 3.99161 61.7268 4.38935C62.4408 4.77288 62.9946 5.34818 63.3881 6.11525C63.7961 6.86812 64.0001 7.77724 64.0001 8.84262L64.0001 15.7676L60.9398 15.7676L60.9398 9.24746C60.9398 8.30993 60.6994 7.59257 60.2185 7.0954C59.7376 6.58402 59.0818 6.32833 58.2511 6.32833C57.4059 6.32833 56.7356 6.58402 56.2401 7.0954C55.7592 7.59257 55.5187 8.30993 55.5187 9.24746L55.5187 15.7676L52.4584 15.7676L52.4584 0L55.5187 0L55.5187 5.43341C55.9122 4.92203 56.4368 4.52429 57.0926 4.24019C57.7484 3.94189 58.477 3.79274 59.2785 3.79274Z" fill="${fillColor}"/>
    <path transform="scale(${scale})" d="M14.9015 5.13538C13.0803 1.32695 8.41948 -0.326121 4.51322 1.4498C0.606164 3.22494 -1.08971 7.76816 0.732198 11.5758C2.04917 14.3291 4.84984 15.955 7.77734 15.9744L5.34122 20.1063L7.83079 21.5012L12.2316 14.0375L7.82839 6.60572L5.34122 8.00531L8.38438 13.1434C6.32715 13.3743 4.25557 12.3199 3.33345 10.3924C2.18079 7.98354 3.25368 5.10894 5.7257 3.98616C6.40054 3.67981 7.11048 3.53518 7.80925 3.53518C9.67264 3.53518 11.461 4.56621 12.2994 6.31803C12.8985 7.56988 12.8953 8.94692 12.4087 10.1389L14.0639 12.9326C15.7374 10.7547 16.1618 7.76971 14.9015 5.13538Z" fill="#FF662D"/>
  </svg>`;
};

const ready = () => {
  const emElements = document.getElementsByClassName(LOGO_CLASS_NAME);
  const tmpEmElements = [...emElements];
  for (const el of tmpEmElements) {
    if (el.tagName.toLowerCase() === LOGO_TAG_NAME) {
      let computedFontSize = getComputedStyle(el).fontSize
        .replace('px', '') // remove px
        .split('.')[0]; // take only integer part
      const fillColor = el.getAttribute(LOGO_FILL_ATTR) || LOGO_FILL_DEFAULT;
      const svgElHTML = getLogoSVG(computedFontSize, fillColor);
      if (svgElHTML && el.outerHTML) {
        el.outerHTML = svgElHTML;
      }
    }
  }
};
document.addEventListener('DOMContentLoaded', ready);
